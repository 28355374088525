<template>

<b-overlay
  :show = "this.loading"
  spinner-variant="primary"
>
  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('Εγγραφές') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
         
        </b-col>

        <!-- Search
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              type="search"
              class="d-inline-block mr-1"
              :placeholder="$t('Αναζήτηση...')"
            />
            
          </div>
        </b-col> -->
      </b-row>

    </div>

    <b-table
      :key = "keykey"
      ref="refCreditTermsListTable"
      :items="creditTerms"
      responsive
      :fields="tableColumns"  
      :filter="searchQuery"
      primary-key="number"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NotFound' )+ ' ' + $t('CreditAgreements')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      :no-provider-sorting="true"
      :no-provider-paging="true"
      :no-provider-filtering="true"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>{{ $t('Loading') }}...</strong>
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(state)="data">
        <template v-if="data.value == 'pending'">
          <b-badge
            pill
            variant="light-warning"
          >
            pending
          </b-badge>
        </template>
        <template v-else-if="data.value == 'approved'">
          <b-badge
            pill
            variant="light-success"
          >
            approved
          </b-badge>
        </template>
        <template v-else-if="data.value == 'rejected'">
          <b-badge
            pill
            variant="light-danger"
          >
            rejected
          </b-badge>
        </template>
        
        <template v-else>
          {{ data.value }}
        </template>
      </template>


      <!-- Column: Actions -->
      <template #cell(actions)="data">
       <b-row class="text-center">
        <b-col class="text-nowrap"  cols="4">
          <feather-icon
            :id="`order-row-${data.item.id}-check-icon`"
            icon="CheckIcon"
            stroke="green"
            size="16"
            @click.stop="approveCreditTerms(data)"
            class="cursor-pointer"
            />
          <b-tooltip
            :title="$t('Approve')"
            placement = "left"
            variant="primary"
            :target="`order-row-${data.item.id}-check-icon`"
          />
        </b-col>
        <b-col class="text-nowrap" cols="4">
          <feather-icon
            :id="`order-row-${data.item.id}-x-icon`"
            icon="XIcon"
            stroke="red"
            size="16"
            @click.stop="rejectCreditTerms(data)"
            class="cursor-pointer"
            />
           <b-tooltip
            :title="$t('Reject')"
            placement = "right"
            variant="primary"
            :target="`order-row-${data.item.id}-x-icon`"
          /> 
        </b-col>
       </b-row>
      </template>


    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{$t("Showing")}} {{ from }} {{$t("to")}} {{ to }} {{$t("of")}} {{ of }} {{$t("entries")}}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            :per-page="perPage"
            v-model="currentPage"
            first-number
            last-number
            :total-rows="of"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BSpinner, BOverlay, 
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moduleUser from '@/store/user/moduleUser.js'
import moduleCreditTerms from '@/store/credit-terms/moduleCreditTerms.js'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
    BOverlay,
    vSelect,
    moduleCreditTerms,
    moduleUser,
    ToastificationContent
  },
  data() {
    return {

      keykey:0,
      creditTerms:[],
      loading: true,
      user: null,
      tableColumns : [
        { key: 'id', label: 'ID' ,sortable: true },
        { key: 'buyer',  label: 'ΠΕΛΑΤΗΣ', sortable: true },
        { key: 'tax_id',  label: 'ΑΦΜ', sortable: true },
        { key: 'supplier',  label: 'ΠΡΟΜΗΘΕΥΤΗΣ', sortable: true },
        { key: 'state', label: 'ΚΑΤΑΣΤΑΣΗ', sortable: true },
        { key: 'actions' , label: 'ΕΝΕΡΓΕΙΕΣ'},],
      perPage : 10,
      currentPage: 1,
      perPageOptions : [10, 25, 50, 100],
      searchQuery : '',
      sortBy : 'number',
      isSortDirDesc :true,
    }
  },
  mounted(){
      this.$root.$on('refresh:button', () => {
          this.refetchCreditTerms();
      });
  },
  computed:{
    localItemsCount(){
      return this.creditTerms.length;
    },
    from(){ 
      return  this.perPage * (this.currentPage - 1) + (this.localItemsCount ? 1 : 0);
    },
    to(){  
      return (this.perPage * this.currentPage) < this.localItemsCount ? this.perPage * this.currentPage : this.localItemsCount
    },
    of(){  
      return this.localItemsCount
    },
  },

  methods: {
    approveCreditTerms(data) {
      if (this.creditTerms[data.index].state !=  "approved"){
        this.loading=true
        this.$store.dispatch("creditTerms/approveCreditTerms", [data.item.id])
        .then(()=>{
          this.creditTerms[data.index].state = "approved"
        })
        .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                  title: this.$t("Error approving Terms"),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
              },
            })
          })
        .finally(()=>{
          this.keykey++
          this.loading=false
        })
      }
    },
    rejectCreditTerms(data) {
      if (this.creditTerms[data.index].state !=  "rejected"){
        this.loading=true        
        this.$store.dispatch("creditTerms/rejectCreditTerms", [data.item.id])
          .then(()=>{this.creditTerms[data.index].state =  "rejected"})
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                  title: this.$t("Error removing Terms"),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
              },
            })
          })
        .finally(()=>{
          this.keykey--
          this.loading=false
        })
      }
    },
    refetchCreditTerms(){

      this.loading = true
      this.$store.dispatch("creditTerms/getCreditTerms")
        .then((data)=>{
            let temp = data.data.result
            this.creditTerms = temp.map(x=>{ 
              return {
                id:x.id,
                buyer:x.buyer.company.name,
                tax_id:x.buyer.company.tax_id,
                supplier: x.supplier.name,
                state: x.status,
              }
            })             
        }) 
        .catch(()=>{this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t("Error fetching Credit Terms"),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })          
        })
        .finally(()=>{
            this.loading = false               
            this.searchQuery=''
            this.currentPage = 1   
            this.keykey++         
        }) 
    }
  },

  created() {
    this.loading = true;

    if (!store.hasModule('user')) store.registerModule('user', moduleUser)
    this.user = this.$store.state.user

    if(this.user==null){
      this.$store.dispatch("user/getUserDetails")
        .then( (user) => {
            this.user = user;                  
            })
        .catch(() => {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t("Error fetching user"),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
    }
    this.$store.dispatch("creditTerms/getCreditTerms")
        .then((data)=>{
            let temp = data.data.result
            this.creditTerms = temp.map(x=>{ 
              return {
                id:x.id,
                buyer:x.buyer.company.name,
                tax_id:x.buyer.company.tax_id,
                supplier: x.supplier.name,
                state: x.status,
              }
            })             
        }) 
        .catch(()=>{this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t("Error fetching Credit Terms"),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })          
        })
        .finally(()=>{
            this.loading = false            
        })        
  },
}
</script>


<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
